import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-centros-evaluacion',
  templateUrl: './centros-evaluacion.component.html',
  styleUrls: ['./centros-evaluacion.component.scss']
})
export class CentrosEvaluacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
