import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseDatosComponent } from './base-datos/base-datos.component';
import { CentrosEvaluacionComponent } from './centros-evaluacion/centros-evaluacion.component';
import { CertificacionComponent } from './certificacion/certificacion.component';
import { ContactoComponent } from './contacto/contacto.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { FaqComponent } from './faq/faq.component';
import { FormacionContinuaComponent } from './formacion-continua/formacion-continua.component';
import { GlosarioComponent } from './glosario/glosario.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';


const routes: Routes = [
  {
    path: 'quienes_somos',
    component: QuienesSomosComponent
  },
  {
    path: 'certificacion',
    component: CertificacionComponent
  },
  {
    path: 'base_datos',
    component: BaseDatosComponent
  },
  {
    path: 'centros_evaluacion',
    component: CentrosEvaluacionComponent
  },
  {
    path: 'formacion_continua',
    component: FormacionContinuaComponent
  },
  {
    path: 'novedades',
    component: NovedadesComponent
  },
  {
    path: 'documentos',
    component: DocumentosComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'enlaces',
    component: EnlacesComponent
  },
  {
    path: 'glosario',
    component: GlosarioComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: '',
    redirectTo: 'quienes_somos',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
