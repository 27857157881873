import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { CertificacionComponent } from './certificacion/certificacion.component';
import { BaseDatosComponent } from './base-datos/base-datos.component';
import { CentrosEvaluacionComponent } from './centros-evaluacion/centros-evaluacion.component';
import { FormacionContinuaComponent } from './formacion-continua/formacion-continua.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { FaqComponent } from './faq/faq.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { GlosarioComponent } from './glosario/glosario.component';
import { ContactoComponent } from './contacto/contacto.component';
import { MenuComponent } from './shared/menu/menu.component';
import { AsideComponent } from './shared/aside/aside.component';

@NgModule({
  declarations: [
    AppComponent,
    QuienesSomosComponent,
    CertificacionComponent,
    BaseDatosComponent,
    CentrosEvaluacionComponent,
    FormacionContinuaComponent,
    NovedadesComponent,
    DocumentosComponent,
    FaqComponent,
    EnlacesComponent,
    GlosarioComponent,
    ContactoComponent,
    MenuComponent,
    AsideComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
