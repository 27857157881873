import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formacion-continua',
  templateUrl: './formacion-continua.component.html',
  styleUrls: ['./formacion-continua.component.scss']
})
export class FormacionContinuaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
